import * as React from "react";
import Container from "../../components/container";
import Layout from "../../components/layout";
// import { StaticImage } from "gatsby-plugin-image";
import HubspotForm from "react-hubspot-form";
import {
  mainContent,
  introSection,
  description,
  row,
  col6,
  col12,
  // marginTopSubstitute,
  hubSpotForm,
} from "../subpage.module.scss";

const ArtAnmeldungPage = () => {
  return (
    <Layout pageTitle="Anmeldung ART-Event" neutralPage={true}>
      <main className={mainContent}>
        <section className={introSection}>
          <Container neutralPageWidth={true}>
            <div className={row}>
              <div className={col12}>
                <p className={description}>
                  Mittwoch 14. September 2022, Basel
                </p>
                <h1>ART-Event</h1>
                <p className="text-medium">
                  Wir laden Sie herzlich zu unserem ART-Event ein. Das Modul
                  spart Zeit und steigert die Wirtschaftlichkeit. Digitale
                  Erkenntnisse und gute Stimmung sind bei uns vorprogrammiert.
                  Wir freuen uns auf Sie.
                </p>

                <div className={row}>
                  <div className={col6}>
                    <h3>ART – Anfrage und Rückweisungs Tool</h3>
                    <p>
                      Zusammen mit dem Universitätsspital Basel (USB)
                      organisieren wir einen ART-Event. Das USB berichtet, wie
                      seine Mitarbeitenden durch das ART-Modul eine nachhaltige
                      Lösung gefunden haben. Unter anderem wird auch
                      beschrieben, wie das ART-Modul am USB täglich eingesetzt
                      wird.
                    </p>
                    <p>
                      ART ist die hilfreiche Softwarelösung zur Bearbeitung von
                      Anfragen und Rückweisungen der Kostenträger im Alltag des
                      Gesundheitswesens. Das Modul ist schon seit mehreren
                      Jahren bei einigen Spitälern in der Schweiz im Einsatz,
                      die Spitäler konnten durch das ART-Modul messbar
                      wirtschaftlicher arbeiten. Weitere Details zum Event
                      werden folgen.{" "}
                    </p>
                    <p>
                      Das Tool hat vorteilhafte Eigenschaften und kann Sie bei
                      der Arbeit effizient und professionell unterstützen.{" "}
                    </p>
                    <p>
                      Mehr Informationen zum ART-Modul finden Sie{" "}
                      <a
                        href="https://www.tie.ch/module/#art"
                        target="_blank"
                        rel="noreferrer"
                      >
                        hier
                      </a>
                      .{" "}
                    </p>
                    <p>
                      Unsere Broschüren finden Sie unter diesem{" "}
                      <a
                        href="https://www.tie.ch/landingpages/booklets/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Link.
                      </a>
                    </p>
                  </div>

                  <div className={col6}>
                    <h3>Ort & Zeit </h3>
                    <p> 750m vom USB entfernt (10min Laufweg)</p>
                    <p>
                      {" "}
                      Hotel Odelya, Missionsstrasse 21, 4055 Basel, Schweiz.
                      15:00 Uhr - ca. 16:30 Uhr mit Apéro Riche im Anschluss.
                    </p>

                    <h3>Agenda </h3>
                    <p>
                      15:00 Uhr <br />
                      Treffpunkt im{" "}
                      <a href="https://www.odelya.ch/de/basel/lage">
                        Odelya Hotel - Seminar Raum
                      </a>
                    </p>
                    <p>
                      {" "}
                      15:10 Uhr <br /> Eröffnungsrede: USB
                    </p>
                    <p>
                      {" "}
                      15:15 Uhr <br /> Fachvortrag ART-Abteilung USB <br />{" "}
                      Diskussion{" "}
                    </p>
                    <p>
                      {" "}
                      16:00 Uhr <br /> Inputreferat KSGR{" "}
                    </p>
                    <p>
                      {" "}
                      16:15 Uhr <br /> Diskussion & Austausch
                    </p>
                    <p> ca.16:30 Uhr - Apéro Riche</p>
                  </div>
                </div>
              </div>
            </div>
            <div className={row}>
              <div className={col12}>
                <h3>
                  Anmeldung durch Ihren Ansprechpartner oder dieses
                  Anmeldeformular.
                </h3>
                <div className={hubSpotForm}>
                  <HubspotForm
                    portalId="9456519"
                    formId="cfec9ec4-68b7-4ee5-bcd8-f1d761e9bb34"
                    region="na1"
                    onSubmit={() => console.log("Submit!")}
                    // onReady={(form) => console.log("Form ready!")}
                    onReady={() => {
                      const script = document.createElement("script");
                      script.defer = "defer";
                      script.src =
                        "https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js";
                      let jqueryScript =
                        document.getElementsByTagName("script");
                      let src = Array.from(jqueryScript).filter(
                        (item) => item.src === script.src
                      );
                      if (src.length === 0) {
                        document.body.appendChild(script);
                      }
                    }}
                    loading={<p>Formular wird geladen...</p>}
                  />
                </div>
              </div>
            </div>
          </Container>
        </section>
      </main>
    </Layout>
  );
};

export default ArtAnmeldungPage;
